<template>
    <component :is="basic ? 'div' : Page" v-if="config.calendar?.enabled">
        <div v-if="year && year.eventsByDate[day.toString()] || basic" :class="basic ? 'py-5' : ''">
            <CommunityNav v-if="!basic" />

            <h2
                class="d-flex justify-content-between align-items-start align-items-md-center
                    flex-column flex-md-row gap-2"
            >
                <span>
                    <Icon v="calendar-star" />
                    <nuxt-link :to="{ name: 'calendar' }"><T>calendar.headerLong</T></nuxt-link>
                    <small class="text-muted">({{ day }})</small>
                </span>
                <nuxt-link v-if="basic" :to="{ name: 'calendar' }" class="fs-4">
                    <Logo />
                    <T>domain</T>/{{ config.calendar.route }}
                </nuxt-link>
            </h2>

            <AdPlaceholder v-if="!basic" phkey="main-0" />

            <section>
                <div class="d-flex justify-content-evenly flex-column-reverse flex-md-row align-items-center align-items-md-start">
                    <div class="calendar-month my-3">
                        <h3 class="text-center">
                            <T>calendar.months.{{ day.month }}</T>
                        </h3>
                        <Calendar :year="year!" :month="day.month" :mark="day" />
                    </div>
                    <div class="calendar-events my-3">
                        <h3>
                            <DateWords :day="day" />
                        </h3>
                        <ul class="list-unstyled mb-0">
                            <li v-for="event in year!.eventsByDate[day.toString()]" class="mb-2">
                                <CalendarEvent :key="event.name" :event="event" :year="year!.year" ics />
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <template v-if="!basic">
                <AdPlaceholder :phkey="['content-1', 'content-mobile-1']" />
                <CalendarExtra :day="day" />

                <Separator icon="heart" />
                <Support />

                <section>
                    <Share :title="$t('calendar.header')" />
                </section>
            </template>
        </div>
        <NotFound v-else />
    </component>
</template>

<script lang="ts">
import { useNuxtApp, useRoute } from 'nuxt/app';
import { defineComponent } from 'vue';

import Page from '~/components/Page.vue';
import useConfig from '~/composables/useConfig.ts';
import useSimpleHead from '~/composables/useSimpleHead.ts';
import { buildCalendar } from '~/src/calendar/calendar.ts';
import { Day } from '~/src/calendar/helpers.ts';

export default defineComponent({
    setup() {
        definePageMeta({
            name: 'calendarDay',
            translatedPaths: (config) => {
                if (!config.calendar?.enabled) {
                    return [];
                }
                return [`/${encodeURIComponent(config.calendar.route)}/:year(\\d\\d\\d\\d)-:month(\\d\\d)-:day(\\d\\d)`];
            },
            middleware: 'layout-from-query',
        });

        const { $translator: translator } = useNuxtApp();
        const runtimeConfig = useRuntimeConfig();
        const route = useRoute();

        const day = new Day(
            parseInt(route.params.year as string),
            parseInt(route.params.month as string),
            parseInt(route.params.day as string),
        );

        useSimpleHead({
            title: translator.translate('calendar.headerLong'),
            banner: `calendar/${day}.png`,
        }, translator);
        return {
            config: useConfig(),
            day,
            year: buildCalendar(runtimeConfig.public.baseUrl).getYear(day.year),
            Page,
        };
    },
    data() {
        return {
            basic: this.$route.query.layout === 'basic',
        };
    },
});
</script>

<style lang="scss" scoped>
    .calendar-month {
        width: min(18rem, 100%);
    }
    .calendar-events {
        width: min(20rem, 100%);
    }
</style>
