<template>
    <component :is="basic ? 'div' : Page" v-if="config.calendar?.enabled">
        <div v-if="year" :class="basic ? 'py-5' : ''">
            <CommunityNav v-if="!basic" />

            <h2
                class="d-flex justify-content-between align-items-start align-items-md-center
                    flex-column flex-md-row gap-2"
            >
                <span>
                    <Icon v="calendar-star" />
                    <T>calendar.headerLong</T> <small class="text-muted">({{ year.year }})</small>
                </span>
                <nuxt-link v-if="basic" :to="{ name: 'calendar' }" class="fs-4">
                    <Logo />
                    <T>domain</T>/{{ config.calendar.route }}
                </nuxt-link>
                <span v-else class="btn-group">
                    <button :class="['btn', labels ? 'btn-outline-primary' : 'btn-primary']" @click="labels = false">
                        <Icon v="table" />
                        <T>calendar.view.grid</T>
                    </button>
                    <button :class="['btn', labels ? 'btn-primary' : 'btn-outline-primary']" @click="labels = true">
                        <Icon v="list" />
                        <T>calendar.view.list</T>
                    </button>
                </span>
            </h2>

            <CalendarBanner v-if="!basic && year.isCurrent()" />

            <AdPlaceholder v-if="!basic" phkey="main-0" />

            <template v-if="basic">
                <section v-if="labels" class="columns-4 pb-4">
                    <div v-for="i in 12" class="py-3">
                        <h3 class="text-center">
                            <T>calendar.months.{{ i }}</T>
                        </h3>
                        <CalendarMonthEvents :year="year" :month="i" class="small my-3" />
                    </div>
                </section>

                <section v-else class="row pb-4">
                    <div v-for="i in 12" class="col-12 col-sm-6 col-lg-3 py-3">
                        <h3 class="text-center">
                            <T>calendar.months.{{ i }}</T>
                        </h3>
                        <Calendar :year="year" :month="i" />
                    </div>
                </section>
            </template>
            <template v-else>
                <section v-if="labels">
                    <CalendarEventsList :year="year" />
                </section>
                <section v-else class="row">
                    <div v-for="i in 12" class="col-12 col-sm-6 col-lg-4 py-3">
                        <h3 class="text-center">
                            <T>calendar.months.{{ i }}</T>
                        </h3>
                        <Calendar :year="year" :month="i" :mark="today" tooltips />
                    </div>
                </section>
            </template>

            <template v-if="!basic">
                <AdPlaceholder :phkey="['content-1', 'content-mobile-1']" />
                <CalendarExtra :year="year" />

                <Separator icon="heart" />
                <Support />

                <section>
                    <Share :title="$t('calendar.header')" />
                </section>
            </template>
        </div>
        <NotFound v-else />
    </component>
</template>

<script lang="ts">
import { useNuxtApp, useRoute } from 'nuxt/app';
import { defineComponent } from 'vue';

import Page from '~/components/Page.vue';
import useConfig from '~/composables/useConfig.ts';
import useSimpleHead from '~/composables/useSimpleHead.ts';
import { buildCalendar } from '~/src/calendar/calendar.ts';
import { Day } from '~/src/calendar/helpers.ts';

export default defineComponent({
    setup() {
        definePageMeta({
            name: 'calendar',
            translatedPaths: (config) => {
                if (!config.calendar?.enabled) {
                    return [];
                }
                return [`/${encodeURIComponent(config.calendar.route)}/:year(\\d\\d\\d\\d)?`];
            },
            middleware: 'layout-from-query',
        });

        const { $translator: translator } = useNuxtApp();
        const runtimeConfig = useRuntimeConfig();
        const route = useRoute();
        const config = useConfig();

        const calendar = buildCalendar(runtimeConfig.public.baseUrl);
        const year = route.params.year
            ? calendar.getYear(parseInt(route.params.year as string))
            : calendar.getCurrentYear();

        if (year) {
            useSimpleHead({
                title: translator.translate('calendar.headerLong'),
                banner: `calendar/${year.year}-overview.png`,
            }, translator);
        }
        return {
            config,
            year,
            Page,
        };
    },
    data() {
        return {
            today: Day.today(),
            basic: this.$route.query.layout === 'basic',
            labels: this.$route.query.labels === 'true',
        };
    },
});
</script>
