import { default as abuseReportsoe5Wtz7uKUMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93IXYrwsWzaRMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexocg5akUCgSMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/index.vue?macro=true";
import { default as moderationuZrwULv6coMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/moderation.vue?macro=true";
import { default as pendingBanse0CsQEpNL3Meta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/pendingBans.vue?macro=true";
import { default as profiles0XMSuSkItfMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/profiles.vue?macro=true";
import { default as expensesWWztDXpKBQMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexz60ThvxWwGMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/timesheets/index.vue?macro=true";
import { default as overview4tRBs0EPJGMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingXR3nfWwcF0Meta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/translations/awaiting.vue?macro=true";
import { default as missinglhy65zChrAMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/translations/missing.vue?macro=true";
import { default as usersG7y2IMu8OCMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/users.vue?macro=true";
import { default as apilKTqZDnhLUMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/api.vue?macro=true";
import { default as _91slug_939bjcx63mnRMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/blog/[slug].vue?macro=true";
import { default as index7q4Bl2flJsMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93ib8rhbqmdVMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93niScspCkioMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminrvesKKF5tyMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/census/admin.vue?macro=true";
import { default as indexAj9ZoTyhv9Meta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/census/index.vue?macro=true";
import { default as contactU92sSZNnH1Meta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/contact.vue?macro=true";
import { default as designvj7t964tKvMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/design.vue?macro=true";
import { default as englishayxmwZdxItMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/english.vue?macro=true";
import { default as faqQQJx10dJK0Meta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/faq.vue?macro=true";
import { default as inclusive4hJ7wPAUblMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/inclusive.vue?macro=true";
import { default as indexbGSTVPMblpMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/index.vue?macro=true";
import { default as licenseREsmZSNAStMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/license.vue?macro=true";
import { default as academic98FkmsbH6RMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/links/academic.vue?macro=true";
import { default as indexGk9tgO7MWaMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/links/index.vue?macro=true";
import { default as media5JuX1KItsJMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/links/media.vue?macro=true";
import { default as translinguisticsiTiMDSktqbMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/links/translinguistics.vue?macro=true";
import { default as zineSkwk02PC3FMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/links/zine.vue?macro=true";
import { default as namesIhGbr4VLbEMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/names.vue?macro=true";
import { default as indexz4f3ZgNTS5Meta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/nouns/index.vue?macro=true";
import { default as templates7q9WocrNveMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/nouns/templates.vue?macro=true";
import { default as peopleJNJigjT2IxMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/people.vue?macro=true";
import { default as privacyssCLq58iQwMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/privacy.vue?macro=true";
import { default as _91username_93tUkNMWIfnRMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93hT6EjCnlS0Meta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/profile/card-[username].vue?macro=true";
import { default as editor9qeCLkIvnoMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/profile/editor.vue?macro=true";
import { default as anymtv4hU6KuNMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/any.vue?macro=true";
import { default as askdLEKNjGXcoMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/ask.vue?macro=true";
import { default as avoidingcUhWSyfhDyMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/avoiding.vue?macro=true";
import { default as indexWb3KVxY5GJMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/index.vue?macro=true";
import { default as mirrorQkcgynZ8h6Meta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/mirror.vue?macro=true";
import { default as pronounRHGy1ZjmprMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesgzQv2AYGpjMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/sources.vue?macro=true";
import { default as teamGgu4N23e8WMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/team.vue?macro=true";
import { default as terminologyb3x4go22zsMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/terminology.vue?macro=true";
import { default as terms7zjSvbCyYoMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/terms.vue?macro=true";
import { default as userqMfbSbTq9pMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/user.vue?macro=true";
import { default as workshopspUJq9AVk4zMeta } from "/home/admin/www/en.pronouns.page/release/20241114072645/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsoe5Wtz7uKUMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBanse0CsQEpNL3Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apilKTqZDnhLUMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_939bjcx63mnRMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: index7q4Bl2flJsMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93ib8rhbqmdVMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93niScspCkioMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminrvesKKF5tyMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexAj9ZoTyhv9Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactU92sSZNnH1Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishayxmwZdxItMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqQQJx10dJK0Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusive4hJ7wPAUblMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academic98FkmsbH6RMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexGk9tgO7MWaMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: media5JuX1KItsJMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsiTiMDSktqbMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineSkwk02PC3FMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesIhGbr4VLbEMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexz4f3ZgNTS5Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templates7q9WocrNveMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleJNJigjT2IxMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyssCLq58iQwMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93tUkNMWIfnRMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93hT6EjCnlS0Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editor9qeCLkIvnoMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anymtv4hU6KuNMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askdLEKNjGXcoMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingcUhWSyfhDyMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexWb3KVxY5GJMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorQkcgynZ8h6Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounRHGy1ZjmprMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesgzQv2AYGpjMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamGgu4N23e8WMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyb3x4go22zsMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms7zjSvbCyYoMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userqMfbSbTq9pMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopspUJq9AVk4zMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241114072645/pages/workshops.vue")
  }
]